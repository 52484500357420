function setTotalPrice(totalPrice = '') {
    $('#shop-cart-offcanvas-body #cart-subtotal').text(totalPrice);

}

function redirectByTarget() {
    const langPrefix = document.documentElement.lang;
    if (window.location.pathname.endsWith('cart')) {
        window.location.replace(`/${langPrefix}/product`);
    }
}

function updateSoppingCartAmount(productsAmount) {
    const counterElement = $('.custom-header .shopping-cart-btn #counter');
    const currentValue = parseInt(counterElement.text());
    const newValue = currentValue + productsAmount;
    counterElement.text(newValue);
}

function removeProductFromCartTableDom(productId) {
    const elementToRemovefromTabel = document.querySelector(`#products-cart-table #product-${productId}-row`);
    elementToRemovefromTabel?.parentNode.removeChild(elementToRemovefromTabel);
}

function removeProductFromSidebarDom(productId) {
    const productQuantity = $(`#shop-cart-offcanvas-body #shop-cart-offcanvas-body-wrapper #products-list #product-${productId} #quantity-amount-product-${productId}`);
    const productQuantityValue = parseInt(productQuantity.text());
    const elementToRemove = document.querySelector(`#shop-cart-offcanvas-body #shop-cart-offcanvas-body-wrapper #products-list #product-${productId}`);
    elementToRemove?.parentNode.removeChild(elementToRemove);
    if (document.getElementById("products-list").childElementCount == 0) {
        $('#shop-cart-offcanvas-body #shop-cart-offcanvas-body-wrapper').addClass('d-none');
        $('#shop-cart-offcanvas-body #shop-cart-offcanvas-body-wrapper').removeClass('d-flex');
        $('#shop-cart-offcanvas-body #cart-empty-text').addClass('d-block');
        $('#shop-cart-offcanvas-body #cart-empty-text').removeClass('d-none');
    }
    return productQuantityValue;
}

function removeProductFromCart(event, productId) {
    const url = document.getElementById('removeProductFromCartRoute')?.value;
    const csrfToken = document.getElementById('removeProductFromCartCsrfToken')?.value;
    $.ajax({
        url: url,
        method: "DELETE",
        data: {
            _token: csrfToken,
            product: productId,
        },
        beforeSend: function () {
            const newShopCartAmountValue = removeProductFromSidebarDom(productId);
            updateSoppingCartAmount((-1) * newShopCartAmountValue);
            removeProductFromCartTableDom(productId);
        },
        error: function (error) {
            console.log(error);
        },
        success: function (response) {
            if (response.data?.length === 0) {
                redirectByTarget();
            }
            setTotalPrice(response.total);
        },
        complete: function () {
        }
    });
}

function addToCart(event, productId, quantity = 1, openSidebar = true) {
    const url = $("#addToCartRoute").val();
    const csrfToken = $("#addToCartCsrfToken").val();
    $.ajax({
        url: url,
        method: "post",
        data: {
            _token: csrfToken,
            product: productId,
            quantity: quantity
        },
        beforeSend: function () {

        },
        error: function (error) {
            console.log(error);
        },
        success: function (response) {
            $('#shop-cart-offcanvas-body #shop-cart-offcanvas-body-wrapper').removeClass('d-none');
            $('#shop-cart-offcanvas-body #shop-cart-offcanvas-body-wrapper').addClass('d-flex');
            $('#shop-cart-offcanvas-body #cart-empty-text').removeClass('d-block');
            $('#shop-cart-offcanvas-body #cart-empty-text').addClass('d-none');
            $('#shop-cart-offcanvas-body #shop-cart-offcanvas-body-wrapper #products-list').empty();
            $('#shop-cart-offcanvas-body #shop-cart-offcanvas-body-wrapper #products-list').append(response.html);
            $(`#product-${productId}-row-totalPrice`)?.text(response.data[productId].totalPriceFormat);
            if (openSidebar) {
                const bsOffcanvas = new bootstrap.Offcanvas('#offcanvasExample');
                bsOffcanvas.show();
            }
            updateSoppingCartAmount(quantity);
            setTotalPrice(response.totalFormat);
        },
        complete: function () {
            $(`#addToCartBtn-${productId} svg`).removeClass('d-none');
            $(`#addToCartBtn-${productId} .spinner-border`).addClass('d-none');
        }
    });
}

function updateProductQuantity(event, productId, quantity, table = false) {
    const url = document.getElementById("addToCartRoute").value;
    const csrfToken = document.getElementById("addToCartCsrfToken").value;
    $.ajax({
        url: url,
        method: "post",
        data: {
            _token: csrfToken,
            product: productId,
            quantity: quantity
        },
        beforeSend: function () {
            if (quantity == -1) {
                if ($(`#quantity-amount-product-${productId}`).text() == 1) {
                    removeProductFromCartTableDom(productId);
                    removeProductFromSidebarDom(productId);
                }
                if (table) {
                    $(`#product-${productId}-quantity-counter-component .actions-input .decrease-action .action-icon-box`)?.addClass('d-none');
                    $(`#product-${productId}-quantity-counter-component .actions-input .decrease-action .spinner-border`)?.removeClass('d-none');
                } else {
                    $(`#product-${productId}-quantity-counter-component .decrease-action .action-icon-box `)?.addClass('d-none');
                    $(`#product-${productId}-quantity-counter-component .decrease-action .spinner-border`)?.removeClass('d-none');
                }

            } else if (quantity == 1) {
                $(`#product-${productId}-quantity-counter-component .increase-action .action-icon-box`).addClass('d-none');
                $(`#product-${productId}-quantity-counter-component .increase-action .spinner-border`).removeClass('d-none');
            }
        },
        error: function (error) {

        },
        success: function (response) {
            updateSoppingCartAmount(quantity);
            const productQuantity = $(`#shop-cart-offcanvas-body #shop-cart-offcanvas-body-wrapper #products-list #product-${productId} #quantity-amount-product-${productId}`);
            const productQuantityNewValue = parseInt(productQuantity.text(), 10) + quantity;
            productQuantity.text(productQuantityNewValue);
            $(".quantity-amount-product-"+productId).text(productQuantityNewValue)
            const counterInput = $(`#product-${productId}-quantity-counter-component .actions-input .product-quantity-table-input`);
            counterInput?.val(productQuantityNewValue);
            $(`#product-${productId}-row-totalPrice`).text(response.data[productId]?.totalPriceFormat);
            setTotalPrice(response.totalFormat);
            if (response.data?.length === 0) {
                redirectByTarget()
            }
        },
        complete: function (response) {
            if (quantity == -1) {
                if (table) {
                    $(`#product-${productId}-quantity-counter-component .actions-input .decrease-action .action-icon-box`).removeClass('d-none');
                    $(`#product-${productId}-quantity-counter-component .actions-input .decrease-action .spinner-border`).addClass('d-none');
                } else {
                    $(`#product-${productId}-quantity-counter-component .decrease-action .action-icon-box `).removeClass('d-none')
                    $(`#product-${productId}-quantity-counter-component .decrease-action .spinner-border`).addClass('d-none')

                }
            } else if (quantity == 1) {
                $(`#product-${productId}-quantity-counter-component .increase-action .action-icon-box `).removeClass('d-none')
                $(`#product-${productId}-quantity-counter-component .increase-action .spinner-border`).addClass('d-none')
            }
        }
    });
}

$('.product-quantity-table-input').on('keyup', function (event) {
    const inputValue = event.target.value;
    const productId = $(this).attr('data-product-id');
    if (isNaN(inputValue) || !inputValue || inputValue<0) {
        $(`#product-${productId}-quantity-counter-component .input-number`).removeClass('d-none');
        return;
    } else {
        if (inputValue == 0) {
            removeProductFromCart(event, productId);
        }
        $(`#product-${productId}-quantity-counter-component .input-number`).addClass('d-none');
        const currentProductQuantity = $(`#products-list #product-${productId} #quantity-amount-product-${productId}`).text()
        if (inputValue < currentProductQuantity) {
            addToCart(event, productId, -1 * (currentProductQuantity - inputValue), false);
        } else if (inputValue >= currentProductQuantity) {
            addToCart(event, productId, inputValue - currentProductQuantity, false);
        }

    }
});

function customAddToCart(event, productId) {
    const currentProductQuantity = $(`#products-list #product-${productId} #quantity-amount-product-${productId}`).text();
    const inputValue = $('.actions-input-custom .product-quantity-show-input').val();

    if (parseInt(inputValue) < 1 || inputValue == '' || inputValue.startsWith('-')) {
        $('#invalide_input_text').removeClass('d-none');
        console.log(inputValue);
        return;
    } else {
        $('#invalide_input_text').addClass('d-none');
    }
    if (inputValue < currentProductQuantity) {
        addToCart(event, productId, -1 * (currentProductQuantity - inputValue), false);
    } else if (inputValue >= currentProductQuantity) {
        addToCart(event, productId, inputValue - currentProductQuantity, false);
    }
}

function increaseValue(event) {
    const inputValue = $('.actions-input-custom .product-quantity-show-input');
    let currentValue = parseInt(inputValue.val());
    inputValue.val(parseInt(currentValue + 1));
    $('#collapseExample #quantity-box').text(inputValue.val());
}

function decreaseValue(event) {
    const inputValue = $('.actions-input-custom .product-quantity-show-input');
    let currentValue = parseInt(inputValue.val());
    if (currentValue == 1)
        return;
    inputValue.val(parseInt(currentValue - 1));
    $('#collapseExample #quantity-box').text(inputValue.val());
}
