var swiperModal = new Swiper(".productGallaryModalSwiper", {
    spaceBetween: 10,
    slidesPerView: 6,
    breakpoints: {
        640: {
            slidesPerView: 7,
        },
        768: {
            slidesPerView: 10,
        },
        1024: {
            slidesPerView: 20,
        },
    },
    freeMode: true,
});
var swiperBig = new Swiper(".productGallaryModalSwiper2", {
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    thumbs: {
        swiper: swiperModal,
    },
});

let isZoomed = false;
$(".image-to-zoom").addClass('cursor-zoom-in');

$(".image-to-zoom").on('click', function (e) {
    var clickX = e.pageX - $(this).offset().left;
    var clickY = e.pageY - $(this).offset().top;
    var zoomFactor = isZoomed ? 1 : 3;
    var zoomedWidth = $(this).width() * zoomFactor;
    var zoomedHeight = $(this).height() * zoomFactor;
    var offsetX = -clickX * (zoomFactor - 1);
    var offsetY = -clickY * (zoomFactor - 1);
    $(this).css({
        "transform": "scale(" + zoomFactor + ")",
        "transform-origin": clickX + "px " + clickY + "px",
        "left": offsetX + "px",
        "top": offsetY + "px",

        "width": zoomedWidth + "px",
        "height": zoomedHeight + "px"
    });
    isZoomed = !isZoomed;
    if (isZoomed) {
        $(this).removeClass('cursor-zoom-in');
        $(this).addClass('cursor-zoom-out');
    } else {
        $(this).removeClass('cursor-zoom-out');
        $(this).addClass('cursor-zoom-in');
    }
});

