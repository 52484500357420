const fixedBox = $('.product-show-bottom-collapse');
var waypoint = new Waypoint({
    element: document.getElementById('waypoint'),
    handler: function (direction) {
        if (fixedBox.hasClass('transform-to-bottom-140-custom')) {
            fixedBox.removeClass('transform-to-bottom-140-custom');
        } else {
            fixedBox.addClass('transform-to-bottom-140-custom');
        }
    }
});
$(document).on('click', '#collapseExampleBtn', function () {
    const btnTarget = $('#collapseExampleBtn svg')
    if (btnTarget.hasClass('rotate-180deg')) {
        btnTarget.removeClass('rotate-180deg');
    } else {
        btnTarget.addClass('rotate-180deg');
    }
});
