$('#shipping-estimator-form').on('submit', function (event) {
    event.preventDefault();
    if ($('#countries').val() == null) {
        $('#shepping-country-empty-feedback').removeClass('d-none');
        return;
    } else {
        $('#shepping-country-empty-feedback').addClass('d-none');
    }
    var form = $(this);
    var url = form.attr('action');
    var data = form.serialize();
    $.ajax({
        type: "GET",
        url: url,
        data: data,
        beforeSend: function () {
            $("#shepping-feedback").text('');
            $('#shipping-estimator-form button').prop('disabled', true);
            const wrapper = $('#estimateShoppingModal #shepping-feedback-wrapper');
            wrapper.removeClass('d-flex');
            wrapper.addClass('d-none');
            wrapper.removeClass('custom-unsuccess-alert');
            wrapper.removeClass('custom-success-alert');
        },
        success: function (response) {
            if (response.isThereIsShepping) {
                const wrapper = $('#estimateShoppingModal #shepping-feedback-wrapper');
                wrapper.removeClass('d-none');
                wrapper.addClass('custom-success-alert');
                wrapper.addClass('d-flex');
            } else {
                const wrapper = $('#estimateShoppingModal #shepping-feedback-wrapper');
                wrapper.removeClass('d-none');
                wrapper.addClass('custom-unsuccess-alert');
                wrapper.addClass('d-flex');
            }
            $("#shepping-feedback").text(response.message);
        },
        error: function (response) {
            console.log('error');
        },
        complete: function () {
            $('#shipping-estimator-form button').prop('disabled', false);
        }
    });
});
