function parseQueryString(queryString) {
    var keyValuePairs = queryString.split("&");
    var obj = {};
    for (var i = 0; i < keyValuePairs.length; i++) {
        var pair = keyValuePairs[i].split("=");
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return obj;
}

function validateForm(queryObject) {
    validatePhoneNumber();
    let notRequiredFields = ['_token', 'note','phone'];
    let errors = [];
    for (var key in queryObject) {
        if (notRequiredFields.includes(key)) {
            continue;
        } else {
            if (validator.isEmpty(queryObject[key].trim())) {
                errors.push(key);
                $(`#checkout-form #${[key]}-feedback`)?.removeClass('d-none');
            } else {
                $(`#checkout-form #${[key]}-feedback`)?.addClass('d-none');
            }
        }
    }
    console.log(errors);
    return errors.length === 0;
}
let submitting = false;

$(document).on('submit', '#checkout-form', function (event) {
    if (submitting) {
        return;
    }
    event.preventDefault();
    let form = $(this);
    let data = form.serialize();
    let queryObject = parseQueryString(data);
    const isSuccess = validateForm(queryObject);
    if (isSuccess) {
        var url = form.attr('action');
        submitting = true;
        form.submit(); // Submit the form

    }
});
