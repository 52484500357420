var swiper = new Swiper(".productGallarySwiper", {
    spaceBetween: 15,
    slidesPerView: 6,
    freeMode: true,
    watchSlidesProgress: true,
});
var swiper2 = new Swiper(".productGallarySwiper2", {
    spaceBetween: 10,
    effect:'fade',
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    thumbs: {
        swiper: swiper,
    },
});

