var swiper = new Swiper(".RecommendedProductsSwiper", {
    slidesPerView: 1,
    spaceBetween: 10,
    autoplay: true,
    loop: true,
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 40,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 10,
        },
    },
});
